import React from "react";
import styles from "./card.module.scss";
import { CardHeader } from "shards-react";

/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

function Messages({ messages }) {
  const formatResident = (caseItem) => {
    if (!caseItem) return "";
    const { residents } = caseItem;
    if (!residents) return "";
    const resident1 = residents[0];
    if (!resident1) {
      return "";
    }
    let addString = "";

    if (resident1.first_name) {
      addString += resident1.first_name;
    }

    if (resident1.last_name) {
      addString += " " + resident1.last_name;
    }

    return addString;
  };

  const navigate = useNavigate();
  const shortenMessage = (message) => {
    const maxLength = 25;
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + "...";
    }
    return message;
  };

  return (
    <div css={css``}>
      <CardHeader className="border-bottom">
        <h6 className="m-0">Messages</h6>
      </CardHeader>
      <div className="p-3">
        <table className="w-100">
          <thead>
            <tr>
              <th>Property Owner</th>
              <th>Resident 1</th>
              <th>Address</th>
              <th>Message Date</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => {
              return (
                <tr
                  key={message.id}
                  className={styles["card-item"]}
                  onClick={() => {
                    navigate(
                      `/cases/${message.case.id}/${message.case.organization_id}`
                    );
                  }}
                >
                  <td>{message.case?.owner?.business_legal_name}</td>
                  <td>{formatResident(message.case)}</td>
                  <td>{message.case?.address}</td>
                  <td>{message.sent_at}</td>
                  <td>{shortenMessage(message.content)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        {!messages.length && <span>No messages.</span>}
      </div>
    </div>
  );
}

export default Messages;
